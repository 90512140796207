<template>
  <div>
    <div class="top-contain">
      <div class="Top_box">
        <div>
          <span>
            <span>所属公司：</span>
            <el-select
              v-model="company"
              clearable
              :disabled="AssignToThis"
              placeholder="请选择"
              @change="changCompany"
            >
              <el-option
                v-for="item in companyList"
                :key="item.value"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span style="margin: 10px">
            <span>所属部门：</span>
            <el-select
              v-model="dept"
              clearable
              :disabled="AssignToThis"
              @change="changeDept"
              placeholder="请选择"
            >
              <el-option
                v-for="item in deptList"
                :key="item.value"
                :label="item.deptmentName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span style="margin: 10px">
            <span>所属岗位：</span>
            <el-select
              v-model="post"
              clearable
              :disabled="AssignToThis"
              placeholder="请选择"
              @change="changPost"
            >
              <el-option
                v-for="item in postList"
                :key="item.value"
                :label="item.postName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
        </div>
        <div class="tow">
          <div>
            <span>文件名称：</span>
            <el-select
              class="choose"
              v-model="checkFileList"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in fliesList"
                :key="item.value"
                :label="item.fileName"
                :value="item.fileNo"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            type="primary"
            @click="selectNum"
            style="margin-left: 10px"
          >
            {{ showBtn ? '重新抽题' : '获取抽题数' }}
          </el-button>
          <el-checkbox
            label="打乱排序"
            style="margin-left: 10px; margin-right: 0px"
          ></el-checkbox>
          <!-- <el-checkbox
            label="专题学习"
            style="margin-left: 10px"
            v-model="checkedTheme"
          ></el-checkbox> -->
        </div>
      </div>
    </div>

    <div class="table-contain">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        :height="elementHeight"
        style="width: 80%"
        border
        id="table"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="riskPoint" label="风险点"> </el-table-column>
        <el-table-column prop="riskRespone" label="风险应对措施">
        </el-table-column>
        <el-table-column prop="lean" label="状态" width="200">
          <template slot-scope="scope">
            <el-button
              @click="goStudy(scope.row)"
              type="primary"
              v-if="scope.row.statues == 0"
              >未学习</el-button
            >
            <span v-if="scope.row.statues == 1" style="color: #388ff2"
              >已学习</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        style="left: 10px"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="重新学习"
      :visible.sync="fileDialog"
      width="47"
      top="25vh"
    >
      <div class="file-dialog">
        <p v-if="!showFile">
          您已经完成文件学习！如需重新学习，请点击<el-button
            type="primary"
            @click="showFile = true"
            >重新学习</el-button
          >
        </p>
        <div v-if="showFile">
          <el-select
            v-model="noCheckFileList"
            multiple
            placeholder="请选择不被重新学习的文件"
          >
            <el-option
              v-for="item in fliesAllList"
              :key="item.value"
              :label="item.fileName"
              :value="item.fileNo"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" v-if="showFile" @click="handleReqData"
          >确 定
        </el-button>
        <el-button @click="handleClose"> 取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="抽题"
      :visible.sync="drawDialog"
      width="30%"
      top="25vh"
      class="draw-dialog"
    >
      <div class="inp">
        <span>共 {{ this.count }} 道题，抽取题目数</span
        ><el-button
          class="btn"
          @click="handleDrawnNumber(0)"
          disabled
          icon="el-icon-minus"
        ></el-button
        ><input
          disabled
          type="text"
          v-model="drawncount"
          @input="inputInp"
        /><el-button
          class="btn"
          @click="handleDrawnNumber(1)"
          disabled
          icon="el-icon-plus"
        ></el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="deawQuestion">确定抽题</el-button>
        <el-button @click="drawDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from '../../api/http'

export default {
  data() {
    return {
      elementHeight: 0,
      fliesList: [],
      checkFileList: [],
      tableData: [],
      AssignToThis: null, //指派学习
      showBtn: false,
      //  下拉框
      companyList: [],
      deptList: [],
      postList: [],
      company: '',
      dept: '',
      post: '',
      checkedTheme: false,
      // 抽题
      count: 0,
      drawncount: 0,
      drawDialog: false,
      // 分页
      start: 0,
      end: 10,
      currentPage1: 1,
      curSize: 10,
      // 重新学习
      fileDialog: false,
      showFile: false,
      fliesAllList: [],
      noCheckFileList: [],
    }
  },
  mounted() {
    this.getCompanyList()
    this.getElementHeight()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 130 + 65)
      })
    },
    // 获取公司列表
    getCompanyList() {
      get('/api/Company/GetAllByUserStudy').then((res) => {
        if (res.code == 200) {
          this.companyList = res.data
        }
      })
    },
    getAssignStudyRecord(e, v, t, m) {
      get(
        '/api/AssignStudyRecord/GetPostCount?CompId=' +
          e +
          '&DeptId=' +
          v +
          '&PostId=' +
          t
      ).then((res) => {
        this.count = res.count
        this.drawncount = res.count
        //this.drawDialog = true
        this.AssignDetail(e, v, t, m)
      })
    },
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage1 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    // 下拉框
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((resp) => {
        if (resp.code == 200) {
          this.dept = ''
          this.deptList = resp.data
          if (this.$route.query.deptId) {
            this.dept = Number(this.$route.query.deptId)
          }
        }
      })
    },
    getPost() {
      get('/api/Post?CompId=' + this.company + '&DeptId=' + this.dept).then(
        (res) => {
          this.postList = res.data
        }
      )
    },
    getFliesList() {
      get(
        '/api/RiskStudyByPost/GetNewFileList?CompId=' +
          this.company +
          '&DeptId=' +
          this.dept +
          '&PostId=' +
          this.post
      ).then((res) => {
        this.fliesList = res.data
      })
    },
    changCompany(e) {
      this.company = e
      this.post = ''
      if (e) this.getDeptList()
    },
    changeDept(e) {
      this.dept = e
      this.post = ''
      this.checkFileList = []
      this.getFliesList()
      if (e) this.getPost()
    },
    changPost(e) {
      if (!e) return
      this.post = e
      this.getRecord()
      this.checkFileList = []
      this.getFliesList()
    },
    getRecord() {
      get(
        '/api/RiskStudylist/' +
          JSON.parse(sessionStorage.getItem('userInfo')).userNo +
          '?compid=' +
          this.company +
          '&deptid=' +
          this.dept +
          '&postid=' +
          this.post
      ).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
          this.showBtn = res.data.length != 0 ? true : false
        }
      })
    },
    // 抽题
    selectNum() {
      if (!this.post) {
        this.$message({
          type: 'warning',
          message: '未选择公司/部门/岗位',
        })
        return
      }
      this.getCount()
    },
    getCount() {
      var param =
        '?CompId=' +
        this.company +
        '&DeptId=' +
        this.dept +
        '&PostId=' +
        this.post
      var list = []
      var item = {}
      this.fliesList.map((i) => {
        this.checkFileList.map((e) => {
          if (i.fileNo == e) {
            item = {
              fileNo: i.fileNo,
              fileName: i.fileName,
            }
            if (list.length == 0) {
              list.push(JSON.stringify(item))
            } else {
              list = list + ',' + JSON.stringify(item)
            }
          }
        })
      })
      list = '[' + list + ']'
      var ReqHeader =
        this.checkFileList.length == 0
          ? get('/api/RiskStudyByPost/GetPostCount' + param)
          : post('/api/RiskStudyByPost/GetByFilesCount' + param, list)
      ReqHeader.then((resp) => {
        if (resp.code == 200) {
          this.count = resp.count
          this.drawncount = resp.count
          this.drawDialog = true
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    deawQuestion() {
      var url = ''
      var list = []
      var item = {}
      var reqHeader = function () {}
      var param =
        '?CompId=' +
        this.company +
        '&DeptId=' +
        this.dept +
        '&PostId=' +
        this.post +
        '&QuesNo=' +
        this.drawncount
      if (this.checkFileList.length == 0) {
        url = '/api/RiskStudyByPost/GetQuesByRandom'
        reqHeader = post(url + param)
      } else {
        this.fliesList.map((i) => {
          //按文件抽题
          this.checkFileList.map((e) => {
            if (i.fileNo == e) {
              item = {
                fileNo: i.fileNo,
                fileName: i.fileName,
              }
              if (list.length == 0) {
                list.push(JSON.stringify(item))
              } else {
                list = list + ',' + JSON.stringify(item)
              }
            }
          })
        })
        list = '[' + list + ']'
        url = '/api/RiskStudyByPost/PostByFiles'
        reqHeader = post(url + param, list)
      }
      reqHeader
        .then((resp) => {
          console.log(resp)
          if (resp.code == '200') {
            if (
              resp.message == '您已经完成文件学习！如需重新学习，请点击重新学习'
            ) {
              this.fliesAllList = this.fliesList
              this.fileDialog = true
              return
            }
            resp.data.map((item) => {
              item.lean = '未学习'
            })
            this.tableData = resp.data
            this.drawDialog = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('获取失败')
        })
    },
    handleDrawnNumber(i) {
      if (this.count == 0) {
        this.$message({
          type: 'warning',
          message: '暂无抽题数',
        })
        return
      }
      if (
        (this.count == 0 && i == 0) ||
        (this.drawncount == this.count && i == 1)
      ) {
        this.$message({
          type: 'warning',
          message: '已超出范围',
        })
        return
      }
      this.drawncount = i == 1 ? this.drawncount + 1 : this.drawncount - 1
    },
    inputInp() {
      if (this.drawncount > this.count) this.drawncount = 0
      if (this.drawncount < 0) this.drawncount = 0
    },
    // 学习
    goStudy(row) {
      put('/api/RiskStudyByPost/' + row.id)
        .then((resp) => {
          if (resp.code == 200) {
            row.statues = 1
          } else {
            this.message.error(resp.message)
          }
        })
        .catch(() => {
          this.message.error('系统异常，请稍后再试')
        })
    },
    // 重新学习,选中的文件不加到参数里
    handleReqData() {
      var list = []
      var item = {}
      if (this.noCheckFileList.length != 0) {
        this.fliesAllList.map((i) => {
          this.noCheckFileList.map((noC) => {
            if (i.fileNo != noC.fileNo) {
              item = {
                fileNo: i.fileNo,
                fileName: i.fileName,
              }
              if (list.length == 0) {
                list.push(JSON.stringify(item))
              } else {
                list = list + ',' + JSON.stringify(item)
              }
            }
          })
        })
      }
      post('/api/RiskStudyByPost/PostReStadyFiles', list)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '开始重新学习',
            })
            this.fileDialog = false
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('重新学习失败')
        })
    },
    handleClose() {
      this.fileDialog = false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/style/Examine_CSS.css';
.top-contain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  .link {
    padding-right: 20px;
    display: flex;
    & .test {
      background-color: transparent !important;
      color: #5a8bff !important;
      text-align: center;
      img {
        width: 48px !important;
        height: 50px !important;
      }
    }
    & .test2 {
      border-left: 5px solid #ececece7;
      border-right: 5px solid #ececece7;
    }
  }
}
.Top_box {
  position: relative;
  padding-top: 20px;
  padding-bottom: 10px;
  > div {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .tow {
    display: flex;
    align-items: center;
  }
}
.table-contain {
  position: relative;
  background-color: #f5f6f8;
  padding: 10px;
  padding-bottom: 60px;
}
.file-dialog {
  text-align: center;
  > p {
    font-size: 14px;
    margin: 10px 0;
  }
  & .el-button {
    margin: 0 5px;
  }
  & .el-select {
    width: 600px !important;
  }
}
.draw-dialog {
  .inp {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 30px;
      font-size: 16px;
      display: inline-block;
      background-color: rgb(238, 237, 237);
      color: blue;
      margin: 0 4px;
      text-align: center;
    }
    input {
      width: 40px;
      border: 1px solid;
      height: 25px;
      border-color: #969696;
      text-align: center;
    }
  }
}
.choose{
  overflow: hidden;
}
/deep/ .choose .el-select__tags{
  flex-wrap: nowrap !important;
}
</style>
